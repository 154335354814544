.slick-menu-compagny button.slick-arrow {
  background-color: aqua !important;
}

/* Styles pour Chrome, Edge et Safari */
::-webkit-scrollbar {
  width: 10px; /* Largeur de la scrollbar */
  height: 10px; /* Pour les scrolls horizontaux */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Couleur du fond */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #000000; /* Couleur de la scrollbar */
  border-radius: 5px;
  transition: background 0.3s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Changement de couleur au survol */
}

/* Styles pour Firefox */
* {
  scrollbar-width: thin; /* Épaisseur fine */
  scrollbar-color: #000000 #f0f0f0; /* Couleur du curseur et du fond */
}

/* Option pour cacher la scrollbar mais garder le scroll actif */
.hidden-scrollbar {
  scrollbar-width: none; /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none; /* Webkit */
}
